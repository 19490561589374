import React from 'react'
import clsx from 'clsx'
import { PageProps } from 'gatsby'
import { Layout } from '../../../components/layout'
import { SEO } from '../../../components/seo'
import { Menu } from '../../../components/menu'

import * as styles from '../../../styles/pages/solutions.module.scss'
import mainHeader from '../../../images/solutions/controladoria/custos.jpeg'

import { Contact } from '../../../components/contact/contact'
import { Footer } from '../../../components/footer'
import { FaqSection } from '../../../components/faq'
import { MethodologySection } from '../../../components/methodology'
import { SliderCustomers } from '../../../components/sliderCustomers/sliderCustomers'
import { StatisticsSection } from '../../../components/statistics'
import { controladoriaList } from '../../../utils/customersList'
import BreadcrumbComponent from '../../../components/breadcrumb'

const faqItems = [
  {
    question: 'Quais são os erros comuns no processo de apuração de custos?',
    answer: `Existem 4 erros muito comuns no momento de apurar custos, como por exemplo: a apuração de custos teórico, possuir um estoque de produtos com valoração errada, não calcular o ciclo financeiro e não colocar a inadimplência nos custos. Estes erros são identificados e corrigidos durante o projeto de consultoria.`,
  },
  {
    question:
      'Quais são os benefícios de um processo de apuração de custos adequado?',
    answer:
      'Um processo de apuração de custos adequado proporciona uma visão clara e precisa dos custos associados à produção de bens ou prestação de serviços. Ao apurar os custos adequadamente, a empresa pode otimizar a alocação de recursos, identificar desperdícios, definir preços competitivos e melhorar a rentabilidade.',
  },
  {
    question: 'Como o custo compõe o orçamento empresarial?',
    answer:
      'O custo desempenha um papel essencial na composição do orçamento empresarial, influenciando diretamente nas projeções financeiras. É fundamental sua apuração para calcular o ponto de equilíbrio e determinar a lucratividade esperada. Ao considerar os custos no orçamento, a empresa consegue estabelecer metas realistas, monitorar o desempenho operacional e fortalecer a base para tomada de decisões estratégicas.',
  },
  {
    question:
      'Como entender se é o momento de contratar uma consultoria em apuração de custos?',
    answer:
      'O momento ideal para contratar uma consultoria em apuração de custos surge quando a empresa enfrenta desafios na compreensão e controle dos seus gastos ou busca aprimorar sua eficiência operacional. Sinais como margens de lucro inconsistentes, dificuldades para encontrar fontes de desperdícios ou falta de clareza no processo de custeio, demandam uma expertise externa. A consultoria pode apoiar, com base no custeio, a tomada de decisões estratégicas, como ampliação ou redução de portfólio, capacidade produtiva, captação de empréstimos a juros menores, aplicação em fundos de investimentos, dentre outras ações.',
  },
]

const methodologyItems = [
  {
    count: 1,
    title: 'Base de dados confiáveis',
    description: (
      <>
        É preciso garantir a confiabilidade dos dados: apontamentos de custos
        (produção, estoque, custos de ociosidade, ineficiência, perdas,
        devoluções, etc), para então classificá-los em custos diretos,
        indiretos, fixos e variáveis.
      </>
    ),
  },
  {
    count: 2,
    title: 'Análise e resultados',
    description: (
      <>
        Após garantir a confiabilidade dos dados que estão sendo apontados, deve
        ser feita a compilação, análise e apresentação dos resultados
        periodicamente.
      </>
    ),
  },
  {
    count: 3,
    title: 'Tomada de decisão',
    description: (
      <>
        Apresentados os resultados periodicamente durante as reuniões de
        conselho, é possível tomar decisões mais assertivas para que a empresa
        se mantenha competitiva no mercado e cresça de forma sustentável.
      </>
    ),
  },
  {
    count: 4,
    title: 'Gestão da mudança',
    description: (
      <>
        Através dos rituais de gestão da mudança organizacional, acompanhamos a
        implementação das ações, definidas durante as reuniões mensais de
        resultado, para otimizar os custos.
      </>
    ),
  },
]

const featuresItems = [
  {
    title: `Redução de riscos`,
    description: (
      <>
        Processos de custeio pouco confiáveis trazem riscos para o negócio, como
        o investimento em produtos ou serviços menos rentáveis, mascaram
        produtos e serviços que poderiam trazer melhores resultados e induzem ao
        erro na formação do preço de venda. Trabalhamos para reduzir estes
        riscos
      </>
    ),
  },
  {
    title: `Boas práticas`,
    description: (
      <>
        Um sistema de custeio bem estruturado permite analisar os custos por
        processos, departamentos, unidades de negócio, produtos e serviços. Além
        disso, permite analisar indicadores de tendência que monitoram custos
        totais e unitários, bem como definir melhores estratégias de
        precificação.
      </>
    ),
  },
  {
    title: `Soluções`,
    description: (
      <>
        Oferecemos soluções com foco na redução de custos e aumento da
        lucratividade por meio da estruturação de método de custeio padrão ou
        por absorção, determinação de margem de contribuição, análise de
        ociosidade de mão de obra e equipamentos, formação do preço de venda,
        estrutura de centros de custos, custos fixos e variáveis e ponto de
        equilíbrio.
      </>
    ),
  },
  {
    title: `Processo vital`,
    description: (
      <>
        A gestão de custos está inserida em uma área vital para realizar um
        melhor planejamento financeiro e elevar a empresa para o próximo nível.
        Uma apuração de custos adequada permite identificar oportunidades de
        investimento, otimizar a margem de lucro, tomar decisões assertivas e
        minimizar riscos operacionais.
      </>
    ),
  },
]

const ControladoriaCustosPage: React.FC<PageProps> = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="High Jump - Apuração de Custos" />
      <div className={styles.solutions}>
        <Menu mainFolder="Controladoria" />
        <main>
          <section className={clsx(styles.intro, styles.singleTitle)}>
            <img src={mainHeader} alt="High jump Consulting" />
            <div className={clsx(styles.mainTitle)}>
              <h1>
                Gestão de custos como premissa para melhorar o resultado
                empresarial
              </h1>
              <p>
                Maximize o resultado através de modelos confiáveis de apuração e
                monitoramento de custos para identificação de oportunidades de
                redução
              </p>
            </div>
          </section>
          <div className={styles.container}>
            <BreadcrumbComponent replacedLabel="Apuração de Custos" />
            <div className={styles.intention}>
              <div className={styles.title}>
                <h1>Nosso Propósito</h1>
                <span></span>
                <p>
                  Aplicar boas práticas de planejamento e gestão empresarial
                  para alavancar resultados, através de um processo confiável de
                  apuração e redução de custos
                </p>
              </div>
              <div className={styles.mainFeatures}>
                <div className={styles.listItems}>
                  {featuresItems.map((item, key) => (
                    <div className={styles.item} key={key}>
                      <h4>{item.title}</h4>
                      <p>{item.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <MethodologySection
              items={methodologyItems}
              descriptionSection="Conheça os 04 principais passos de nossa metodologia para estruturação do processo de custeio."
            />
          </div>

          <div className={styles.cases}>
            <div className={styles.title}>
              <h1>Quem conhece, confia!</h1>
            </div>
            <SliderCustomers customers={controladoriaList} />
          </div>

          <div className={styles.statistics}>
            <StatisticsSection />
          </div>

          <div className={styles.container}>
            <div className={styles.features}>
              <div className={styles.title}>
                <h1>Diferenciais</h1>
              </div>
              <div className={styles.items}>
                <div className={styles.div1}>
                  <h2>Equipe multidisciplinar</h2>
                  <p>
                    Especialistas por área, trazendo as melhores práticas de
                    mercado
                  </p>
                </div>
                <div className={styles.div2}>
                  <h2>Boas práticas FIPECAFI</h2>
                  <p>Aplicação de boas práticas contábeis e fiscais</p>
                </div>
                <div className={styles.div3}>
                  <h2>Confiança no resultado</h2>
                  <p>Almejando o retorno sobre o investimento</p>
                </div>
                <div className={styles.div4}>
                  <h2>Modalidade híbrida</h2>
                  <p>
                    Execução presencial e/ou remota em comum acordo com o
                    cliente
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Contact />
          <FaqSection items={faqItems} />
          <Footer />
        </main>
      </div>
    </Layout>
  )
}

export default ControladoriaCustosPage
